<template>
  <v-container
    class="tabBackground"
  >
    <v-row>
      <v-col cols="1" />
      <v-col cols="7">
        <v-text-field
          v-model="searchText"
          append-icon="search"
          :label="$t('tagsTab.search')"
          color="grey darken-1"
          single-line
          hide-details
        />
      </v-col>
      <v-col cols="3"
             class="align-button-end"
      >
        <v-btn
          color="secondary"
          class="mx-2"
          @click="openAddDialog()"
        >
          {{ $t('tagsTab.addBtn') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="selectedTag.length === 0"
          @click="openDeleteDialog()"
        >
          {{ $t('tagsTab.deleteBtn') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          v-model="selectedTag"
          :headers="headers"
          :items="tags"
          :search="searchText"
          :loading="loading"
          item-key="id"
          show-select
          single-select
        />
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('tagsTab.deleteTag.title') }}
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="deleteDialog && selectedTag[0].capture_count"
          >
            <v-col>
              <div class="text-h6 font-weight-bold my-4">
                {{ selectedTag[0].capture_count + $t('tagsTab.deleteTag.confirmHasCaptures') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h6 my-4">
                {{ $t('tagsTab.deleteTag.confirmText') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteTag()"
          >
            {{ $t('tagsTab.deleteTag.delete') }}
          </v-btn>
          <v-btn @click="cancelDelete()">
            {{ $t('tagsTab.deleteTag.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addDialog"
      persistent
      max-width="400px"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6 white--text primary">
            <span class="text-h5">
              {{ $t('tagsTab.addTag.title') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newTag.name"
                  required
                  :label="$t('tagsTab.addTag.name')"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              @click="addTag()"
            >
              {{ $t('tagsTab.addTag.add') }}
            </v-btn>
            <v-btn @click="cancel()">
              {{ $t('tagsTab.addTag.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      v-model="addSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('tagsTab.addTag.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="addErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('tagsTab.addTag.error') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('tagsTab.deleteTag.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('tagsTab.deleteTag.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import tagsService from '../../js/services/tagsService';

export default {
  name: 'Tags',
  data() {
    return {
      success: null,
      error:   {
        messages: [],
        message:  null,
      },
      tags:                  [],
      loading:               'secondary',
      searchText:            '',
      selectedTag:           [],
      settings:              {},
      addDialog:             false,
      deleteDialog:          false,
      addSuccessSnackbar:    false,
      deleteSuccessSnackbar: false,
      addErrorSnackbar:      false,
      deleteErrorSnackbar:   false,
      newTag:                {
        name:   '',
        preset: false,
      },
      headers: [
        {
          text:  this.$t('tagsTab.headers.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('tagsTab.headers.name'),
          align: 'center',
          value: 'name',
        },
        {
          text:  this.$t('tagsTab.headers.captureCount'),
          align: 'center',
          value: 'capture_count',
        },
      ],
    };
  },

  async created() {
    this.loadTags();
  },

  methods: {
    /**
     * Load tags.
     */
    async loadTags() {
      this.loading = 'secondary';
      this.tags = await tagsService.loadTags();
      this.loading = false;
    },

    openAddDialog() {
      this.newTag.name = '';
      this.newTag.preset = false;

      this.addDialog = true;
    },

    async openDeleteDialog() {
      this.deleteDialog = true;
    },

    async deleteTag() {
      try {
        await tagsService.delete(this.selectedTag[0].id);
        this.deleteDialog = false;
        this.deleteSuccessSnackbar = true;
        this.loadTags();
      } catch (ex) {
        this.deleteDialog = false;
        this.deleteErrorSnackbar = true;
      }
    },

    cancelDelete() {
      this.deleteDialog = false;
    },

    async addTag() {
      try {
        await tagsService.createTag(this.newTag);

        this.addDialog = false;
        this.addSuccessSnackbar = true;
        this.loadTags();
      } catch {
        this.addDialog = false;
        this.addErrorSnackbar = true;
      }
    },

    cancel() {
      this.addDialog = false;
    },

  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }
</style>
