<template>
  <div class="ai-analysis">
    <div class="ai-analysis__fullscreen">
      <div v-if="!isFullscreen"
           class="ai-container__breadcrumb"
      >
        <v-breadcrumbs class="text-h2!important"
                       small
                       :items="breadcrumbs"
        >
          <template #item="{ item }">
            <v-breadcrumbs-item :to="item.to"
                                :disabled="item.disabled"
            >
              <h1 v-if="item.disabled"
                  class="subHeader"
              >
                {{ item.text }}
              </h1>
              <h1 v-else
                  class="breadcrumbLink"
              >
                {{ item.text }}
              </h1>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>
      <div v-if="isLoading"
           class="ai-analysis__loading"
      >
        <span class="ai-analysis__loading--text">
          Processing capture, please check back shortly.
        </span>
      </div>
      <div class="ai-analysis__content">
        <v-btn
          v-if="!isError"
          :class="isFullscreen ? 'fullscreen-btn ' : 'not-fullscreen-btn'"
          class="white--text"
          color="transparent"
          fab
          small
          @click="toggleFullscreen()"
        >
          <v-icon large>
            {{ !isFullscreen? 'fullscreen': 'fullscreen_exit' }}
          </v-icon>
        </v-btn>

        <FullScreenViewer
          ref="imageEditor"
          :details="details"
          :highlight-selected="selectedDetail"
          :enable-export="true"
          :handle-hover-detail="handleHoverDetail"
          :clear-selected-detail="clearSelectedDetail"
          :is-fullscreen="isFullscreen"
          @fullscreen-pressed="enterFullscreen()"
          @page-changed="handlePageChanged($event)"
          @cursor-over-lesion="onCursorOverLesion"
        />
      </div>
    </div>
    <div class="sidebar-fullscreen">
      <v-btn class="sidebar-fullscreen__toggle"
             :style="{ right: isFullscreen && sidebarVisible ? '13em' : '-1em'}"
             icon
             @click="toggleSidebar"
      >
        <v-icon>{{ sidebarVisible ? 'arrow_forward_ios' : 'arrow_back_ios' }}</v-icon>
      </v-btn>
      <v-navigation-drawer v-model="sidebarVisible"
                           :class="isFullscreen ? 'sidebar-fullscreen__drawer-fullscreen': ''"
                           class="sidebar-fullscreen__drawer"
                           app
                           clipped
                           right
                           width="200"
      >
        <h2 class="pl-2">
          {{ $t('dermaidStudio.sideBar.results') }}
        </h2>
        <v-list class="scan-details">
          <h4 class="pl-4">
            {{ $t('dermaidStudio.sideBar.detectedlesions') }}
          </h4>
          <v-list-item v-for="detail in details"
                       :key="detail.id"
                       :class="{
                         'selected': selectedDetail === detail,
                         'scan-details-item': true
                       }"
                       @click="handleDetailSelected(detail)"
          >
            <img :src="detail.lesionImage"
                 width="50"
                 height="50"
                 onerror="this.style.display='none'"
                 onload="this.style.display='block'"
            >
            <v-list-item-content>
              <div class="label-value-pair">
                <div>Id: </div>
                <div> {{ detail.id }} </div>
              </div>
              <div class="label-value-pair">
                <div>Score: </div>
                <div>{{ detail.lesionScore.toFixed(2) }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FullScreenViewer from '@/components/imageStudio/FullscreenViewer.vue';
import { ACCOUNT_PAGE, CAPTURE_PAGE } from '../js/router/pages';
import dermaidService from '../js/services/dermaidService';

export default {
  name:       'DermaidStudio',
  components: { FullScreenViewer },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === CAPTURE_PAGE || from.name === ACCOUNT_PAGE) {
        vm.breadcrumbs.push({
          text:     vm.$t(from.meta.title),
          disabled: false,
          to:       from,
        });
      }
      vm.breadcrumbs.push(vm.dermaidStudioBreadcrumb);
    });
  },
  data() {
    return {
      progressMessage:         [],
      polling:                 true,
      accounts:                [],
      prevRoute:               null,
      breadcrumbs:             [],
      details:                 [],
      selectedDetail:          null,
      fileName:                '',
      exportDialog:            false,
      exportLoading:           false,
      exportError:             null,
      downloadUrl:             null,
      originalFileName:        null,
      dermaidStudioBreadcrumb: {
        text:     this.$t('dermaidStudio.title'),
        disabled: true,
        href:     null,
      },
      sidebarVisible:      true,
      isFullscreen:        false,
      isLoading:           undefined,
      isError:             false,
      projectDownloadInfo: { status: 'Created' },
      currentPageIndex:    1,
      windowWidth:         window.innerWidth,
      fullscreenElement:   null,
    };
  },
  computed: {

    ...mapGetters(['isDermaidEnabled']),

    dermaidPresets() {
      return this.$store.getters.dermaidPresetsGetter;
    },
  },
  created() {
    const { projectId } = this.$route.query;

    this.getPagesByProjectId(projectId)
      .then(pages => {
        dermaidService.addPagesToDermaidStudio([]);

        this.fetchResult(pages);
      });
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fullscreenElement = this.$el;

    this.fullscreenElement.addEventListener('fullscreenchange', () => {
      const fullscreenElement = document.fullscreenElement
          || document.mozFullScreenElement
          || document.webkitFullscreenElement;

      if (!fullscreenElement && this.isFullscreen) {
        this.isFullscreen = false;
      }
    });
  },
  beforeDestroy() {
    this.polling = false;
    window.removeEventListener('resize', this.handleResize);
    this.fullscreenElement.removeEventListener('fullscreenchange', () => {
      // eslint-disable-next-line no-console
      console.log('Removed fullscreen change event listener successfully.');
    });
  },
  methods: {
    onCursorOverLesion(event) {
      this.selectedDetail = this.details.find(item => item.id === event.id);
    },
    async getPagesByProjectId(projectId) {
      return dermaidService.getPagesByProjectId(projectId);
    },
    toggleFullscreen() {
      if (this.isFullscreen) {
        this.exitFullscreen();
      } else {
        this.enterFullscreen();
      }
    },
    enterFullscreen() {
      this.isFullscreen = true;
      this.fullscreenElement = this.$el;

      if (this.fullscreenElement.requestFullscreen) {
        this.fullscreenElement.requestFullscreen();
      } else if (this.fullscreenElement.mozRequestFullScreen) {
        this.fullscreenElement.mozRequestFullScreen();
      } else if (this.fullscreenElement.webkitRequestFullscreen) {
        this.fullscreenElement.webkitRequestFullscreen();
      } else if (this.fullscreenElement.msRequestFullscreen) {
        this.fullscreenElement.msRequestFullscreen();
      }
    },

    exitFullscreen() {
      this.isFullscreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },

    async fetchResult(pages) {
      while (this.polling) {
        // eslint-disable-next-line no-await-in-loop
        const results = await Promise.all(
          pages.map(async page => dermaidService.fetchAnalysisByPageId(page)),
        );

        const finished = results.every(item => (
          item.status === 'complete'
          || item.status === 'error_max_retries_exceed'
          || item.status === 'unknown'
        ));

        this.isLoading = !finished;
        this.polling = !finished;

        // eslint-disable-next-line no-await-in-loop
        await this.fetchAndRenderAnalysisData(results);

        // eslint-disable-next-line no-await-in-loop
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    },

    handlePageChanged(newPageIndex) {
      const newPage = dermaidService.getPages()[newPageIndex - 1];

      if (!newPage) {
        // eslint-disable-next-line no-console
        console.error('Page not found');

        return;
      }

      this.selectedDetail = null;
      this.details = newPage.details;
      this.currentPageIndex = newPageIndex;
      this.isError = newPage.isError;
    },

    handleDetailSelected(detail) {
      if (this.selectedDetail === detail) {
        this.selectedDetail = null;
      } else {
        this.selectedDetail = detail;
      }
    },

    handleHoverDetail(detail) {
      this.selectedDetail = detail;
    },

    clearSelectedDetail() {
      this.selectedDetail = null;
    },

    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },

    async fetchAndRenderAnalysisData(analysis) {
      try {
        const pages = analysis
          .filter(result => result.status === 'complete' || result.status === 'error_max_retries_exceed' || result.status === 'unknown')
          .map(pageResult => {
            const images = [];

            if (pageResult.analysis_result?.skin_lesion_image) {
              images.push({
                captureId: pageResult.analysis_result.capture_id,
                frame:     pageResult.analysis_result.frame_number,
                image:     pageResult.analysis_result.skin_lesion_image,
                isError:   false,
              });
            }

            if (pageResult.imageUrl) {
              images.push({
                captureId: pageResult.captureId || 'N/A',
                frame:     images.length,
                image:     pageResult.imageUrl,
                isError:   true,
              });
            }

            return {
              pageType:  'dermaid',
              id:        pageResult.pageId,
              isError:   pageResult.status === 'error_max_retries_exceed' || (pageResult.status === 'unknown' && !pageResult.analysis_result),
              status:    pageResult.status,
              imageUrl:  pageResult.imageUrl,
              captureId: pageResult.captureId,
              angle:     pageResult.angle,
              details:   pageResult.analysis_result?.details,
              images,
            };
          });

        if (pages.length === 0) {
          return;
        }

        const messages = [];

        analysis.forEach(item => {
          messages.push({ pageId: item.pageId, status: item.status });
        });

        this.progressMessage = messages;

        dermaidService.addPagesToDermaidStudio(pages);
        this.$refs.imageEditor.loadStorageDataToPages();
        this.isError = pages[this.currentPageIndex - 1].isError;
        this.details = pages[this.currentPageIndex - 1].details;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching analysis data:', error);
        this.isLoading = true;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../css/variables.scss";
.ai-analysis {
  .ai-container__breadcrumb {
    margin: 0;
    padding: 0;
    width: 80%;
  }

  &__fullscreen {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__loading {
    display: flex;
    flex-direction: column;
    width: 400px;
    color: $grey-darken-1;
    background-color: transparent;
    border: 2px solid $blue-title-2;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    padding: 5px 10px;

    &--icon {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      gap: 15px;
    }

    &--text {
      min-height: 1em;
      color: $grey-darken-1;
      font-size: 1rem;
    }

    &--message {
      list-style: none;
    }
  }
}

.sidebar-fullscreen {
  &__drawer {
    max-height: 100% !important;
  }

  &__drawer-fullscreen {
    top: 0 !important;
  }

  &__toggle {
    position: absolute;
    top: 6%;
    right: -1em;
    transform: translateY(-50%);
    z-index: 200;
    background-color: #EEEEEE !important;
  }
}

.subHeader {
  color: $grey-darken-1;
}

.title {
  background-color: $blue-title-2;
  padding: 16px !important;
  color: #FFFFFF;
}

.padNav {
  margin: 0 300px 0 0;
}

@media screen and (max-width: 1263px) {
  .sidebar-fullscreen {
    &__toggle {
      position: absolute;
      right: 13em;
    }
  }
}

.label-value-pair {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.scan-details h2 {
  margin-top: 15px;
}

.scan-details-item {
  display: flex;
  align-items: center;
  padding: 3px 15px;
  gap: 10px;

  &.selected {
    background-color: rgb(121, 167, 246);
    color: white;
  }
}

.scan-detail-large {
  width: 200px;
  height: 200px;
  display: block;
}

.scan-detail-placeholder {
  background-color: #ccc;
  border: 2px dotted #999;
}

.detail-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 12px 20px 20px 20px;
  border-radius: 8px;
  width: 240px;
}

.fullscreen-btn {
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 1000;
}

.not-fullscreen-btn {
  position: relative;
  top: 6rem;
  z-index: 1000;
}
</style>
