<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('exportCaptureDownloadPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="secondary white--text mr-2"
        @click="downloadFile"
      >
        {{ $t('exportCaptureDownloadPage.download') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>

import captureExportsService from '../js/services/captureExportsService';

export default {
  name: 'ExportCaptureDownloadReview',
  data() {
    return {

    };
  },
  methods: {
    async downloadFile() {
      const downloadUrl = await captureExportsService.getExportUrl(
        this.$route.params.exportCaptureId,
      );

      const downloadElement = document.createElement('a');

      downloadElement.href = downloadUrl;
      downloadElement.id = 'downloadElement';

      document.body.appendChild(downloadElement);
      downloadElement.click();

      setTimeout(() => {
        downloadElement.remove();
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}

</style>
