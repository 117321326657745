<template>
  <div class="logoutContainer">
    <v-img src="@/assets/logo.png"
           max-height="50"
           max-width="274"
           class="mx-auto"
           contain
    />
    <h2 class="text-h4 mt-8 mb-6 subHeader">
      Logged Out Successfully
    </h2>
    <div>
      <p class="text-body2 mb-12">
        You have been logged out of your account.
      </p>
      <v-btn
        color="secondary"
        @click="restartLoginProcess"
      >
        Log In Again
      </v-btn>
    </div>
  </div>
</template>

<script>
import userManager from '../js/auth/userManager';

export default {
  name:    'AuthLogout',
  methods: {
    restartLoginProcess() {
      userManager.signinRedirect();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../css/variables.scss";
  .logoutContainer {
    width: 500px;
    margin: 200px auto;
    text-align: center;
  }

  .subHeader {
    color: $grey-darken-1;
  }
</style>
