import Vue from 'vue';

export default {

  /**
   * Update MFA settings for the organization, including TOTP and global MFA enabled status.
   *
   * @param {Object} mfaSettings - the MFA settings for the organization.
   * @param {boolean} mfaSettings.enabled - whether MFA is globally enabled.
   * @param {boolean} mfaSettings.totpEnabled - whether TOTP (Authenticator) is enabled.
   * @returns {Promise<Object>} the API response.
   */
  async updateMfaSettings(mfaSettings) {
    const response = await Vue.axios.put('/api/organizations/mfa', mfaSettings);

    if (response.status === 200) {
      return { success: true, message: 'Multi-Factor Auth settings updated.' };
    }

    throw new Error('Failed to update MFA settings.');
  },

  /**
   * Get the current MFA settings for the organization.
   *
   * @returns {Promise<Object>} the current MFA settings.
   */
  async getMfaSettings() {
    const response = await Vue.axios.get('/api/organizations/mfa');

    if (response.status === 200) {
      return response.data;
    }

    throw new Error('Failed to fetch MFA settings.');
  },
};
