<template>
  <v-app>
    <SidePanel v-if="withLayout"
               ref="sidePanel"
    />
    <HeaderToolBar v-if="withLayout"
                   @toggle-navbar="toggleNavbar"
    />
    <v-main v-if="withLayout">
      <keep-alive include="Captures">
        <router-view />
      </keep-alive>
    </v-main>
    <v-main v-else>
      <keep-alive include="Captures">
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import HeaderToolBar from './components/HeaderToolBar.vue';
import SidePanel from './components/SidePanel.vue';

export default {
  name:       'App',
  components: {
    HeaderToolBar,
    SidePanel,
  },
  data() {
    return {
      drawer: window.innerWidth > 768,
    };
  },
  computed: {
    withLayout() {
      return !this.$route.meta.withOutLayout;
    },
    imageStudio() {
      return true;
    },
  },
  methods: {
    toggleNavbar() {
      if (this.$refs.sidePanel && this.withLayout) {
        this.$refs.sidePanel.toggleNavbar();
      }
    },

  },
};
</script>
<style scoped>

.v-main {
  background-color: var(--v-pageBackground-base);
}

html {
  overflow-x: auto;
}
</style>
