<template>
  <v-container
    class="px-12"
  >
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('accountPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <div v-if="account.id">
      <v-row>
        <v-col cols="5">
          <AccountDetails
            :v-if="account.id"
            :account-values="account.fields"
            @edit="openEditDialog"
            @delete="openDeleteDialog"
          />
        </v-col>
        <v-col cols="7">
          <v-row>
            <v-col>
              <AccountCaptures
                :account="account"
                @update="loadAccountData"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AccountImageStudio
                :account="account"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AccountVideoStudio
                :account="account"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="account-dermaid-studio">
              <AccountDermaidStudio
                :account="account"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AccountDocuments
                :account="account"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <AccountData
        :dialog="showEditAccount"
        :account="account"
        @close="showEditAccount=false"
        @saved="savedAccount"
        @error="savedError"
      />
      <AccountDelete
        :dialog="showDeleteAccount"
        :account="account"
        @close="showDeleteAccount=false"
        @ok="goToAccountsPage"
      />
      <v-dialog
        v-model="showDeleteError"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title
            class="text-h6 white--text primary"
          >
            {{ $t('accountPage.captureAssociatedErrorTitle') }}
            <v-spacer />
            <v-btn
              icon
              dark
              @click="showDeleteError = false"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-3">
            {{ $t('accountPage.captureAssociatedError') }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="accountNotFound">
      <v-row>
        <v-col cols="12">
          <h2 class="mt-5">
            404 {{ $t('accountPage.notFound') }}
          </h2>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="sucessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('addAccount.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('addAccount.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import AccountDetails from '../components/Account/AccountDetails.vue';
import AccountCaptures from '../components/Account/AccountCaptures.vue';
import AccountImageStudio from '../components/Account/AccountImageStudio.vue';
import AccountVideoStudio from '../components/Account/AccountVideoStudio.vue';
import AccountDocuments from '../components/Account/AccountDocuments.vue';
import AccountData from '../components/Account/AccountData.vue';
import AccountDelete from '../components/Account/AccountDelete.vue';
import accountsService from '../js/services/accountsService';
import breadcrumbsService from '../js/services/breadcrumbsService';
import { ACCOUNTS_PAGE } from '../js/router/pages';
import AccountDermaidStudio from '../components/Account/AccountDermaidStudio.vue';

export default {
  name:       'Account',
  components: {
    AccountDetails,
    AccountCaptures,
    AccountImageStudio,
    AccountVideoStudio,
    AccountDocuments,
    AccountDermaidStudio,
    AccountData,
    AccountDelete,
  },
  data() {
    return {
      showEditAccount:   false,
      showDeleteAccount: false,
      showDeleteError:   false,
      accountNotFound:   false,
      account:           {},
      sucessSnackbar:    false,
      errorSnackbar:     false,
    };
  },
  watch: {
    '$route.params.accountId': {
      handler() {
        this.loadAccountData();
      },
      deep:      true,
      immediate: true,
    },
  },
  mounted() {
    this.loadAccountData();
    this.clearBreadcrumbs();
  },
  methods: {
    /**
     * Save breadcrumbs params.
     */
    clearBreadcrumbs() {
      breadcrumbsService.saveParams({});
    },

    openEditDialog() {
      this.showEditAccount = true;
    },

    openDeleteDialog() {
      if (this.account.captures.length > 0) {
        this.showDeleteError = true;
      } else {
        this.showDeleteAccount = true;
      }
    },

    /**
     * Load account's information.
     */
    async loadAccountData() {
      try {
        this.account = await accountsService.getAccountInfo(this.$route.params.accountId);
      } catch (error) {
        this.accountNotFound = true;
      }
    },

    /**
     * Redirect to accounts search page.
     */
    goToAccountsPage() {
      this.$router.push({
        name: ACCOUNTS_PAGE,
      });
    },

    async savedAccount() {
      this.sucessSnackbar = true;
      this.showEditAccount = false;

      await this.loadAccountData();
    },

    async savedError() {
      this.errorSnackbar = true;
      this.showEditAccount = false;

      await this.loadAccountData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../css/variables.scss";

.subHeader {
  color: $grey-darken-1;
}

.title {
    background-color: $blue-title;
  }

</style>
