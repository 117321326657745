import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

/**
 * Vuex store object.
 */
export default new Vuex.Store({
  state: {
    auth: {
      user:          null,
      authenticated: false,
      referrerRoute: null,
    },
    breadcrumbs:           {},
    captureView:           null,
    accountFields:         [],
    accountDocumentFields: [],
    dermaidPresets:        [],
    isDermaidEnabled:      false,
    organizationSettings:  {},
    imageStudio:           {
      images:  [],
      project: {
        pages: {
          images:   [],
          commands: [],
        },
      },
    },
    dermaidStudio: {
      images:  [],
      project: {
        pages: {
          images:   [],
          commands: [],
        },
      },
    },
    videoStudio: {
      view:       null,
      videos:     [],
      sideBySide: [],
      playlists:  {
        playlist1: [],
        playlist2: [],
      },
    },
  },
  plugins: [
    persistedState({
      paths: [ 'auth', 'imageStudio', 'videoStudio', 'breadcrumbs', 'isDermaidEnabled', 'dermaidStudio' ],
    }),
  ],
  getters,
  mutations,

});
