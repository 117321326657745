<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    :permanent="false"
    :temporary="false"
  >
    <v-list nav>
      <v-list-item-group color="primary">
        <v-list-item
          link
          :to="{name: accountsPage}"
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('sidePanel.accounts') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{name: capturesPage}"
        >
          <v-list-item-icon>
            <v-icon>photo</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('sidePanel.captures') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{name: tagsPage}"
        >
          <v-list-item-icon>
            <v-icon>tag_faces</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('sidePanel.tags') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{name: imageStudioPage}"
        >
          <v-list-item-action>
            <v-badge
              :content="imageStudioCount()"
              :value="imageStudioCount()"
              color="secondary"
              overlap
            >
              <v-icon>photo_library</v-icon>
            </v-badge>
          </v-list-item-action>
          <v-list-item-title>{{ $t('sidePanel.imageStudio') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isDermaidEnabled()"
          link
          :to="{ name: 'dermaidStudio' }"
        >
          <v-list-item-icon>
            <v-icon>camera_front</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            oVio<span class="ai-studio-text">Ai</span> Studio
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          :to="{name: videoStudioPage}"
        >
          <v-list-item-action>
            <v-badge
              :content="videoStudioCount()"
              :value="videoStudioCount()"
              color="secondary"
              overlap
            >
              <v-icon>movie_filter</v-icon>
            </v-badge>
          </v-list-item-action>
          <v-list-item-title>{{ $t('sidePanel.videoStudio') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isAdminUser()"
          link
          :to="{name: settingsPage}"
        >
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('sidePanel.settings') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :href="getHelpUrl()"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>help</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('sidePanel.help') }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import videoStudioService from '../js/services/videoStudioService';
import imageStudioService from '../js/services/imageStudioService';
import userService from '../js/services/userService';
import packageData from '../../package.json';

import {
  ACCOUNTS_PAGE,
  CAPTURES_PAGE,
  TAGS_PAGE,
  IMAGE_STUDIO_PAGE,
  VIDEO_STUDIO_PAGE,
  SETTINGS_PAGE,
} from '../js/router/pages';

export default {
  name: 'SidePanel',
  data() {
    return {
      drawer:          window.innerWidth > 768,
      capturesPage:    CAPTURES_PAGE,
      accountsPage:    ACCOUNTS_PAGE,
      imageStudioPage: IMAGE_STUDIO_PAGE,
      videoStudioPage: VIDEO_STUDIO_PAGE,
      settingsPage:    SETTINGS_PAGE,
      tagsPage:        TAGS_PAGE,
      helpRoot:        'https://help.ovio.app',
      appVersion:      packageData.version,
      /* items: [
        { title: 'Accounts', icon: 'mdi-view-dashboard', target: ACCOUNTS_PAGE },
        { title: 'Captures', icon: 'mdi-image', target: CAPTURES_PAGE },
        { title: 'Tags', icon: 'mdi-image' },
        { title: 'Export Videos', icon: 'mdi-help-box' },
        { title: 'Export Images', icon: 'mdi-help-box' },
        { title: 'Users', icon: 'mdi-help-box' },
      ],*/
    };
  },
  methods: {

    /**
     * Returns true if the user is admin
     *
     * @return {boolean}
     */
    isAdminUser() {
      return userService.isAdmin();
    },
    videoStudioCount() {
      return videoStudioService.getVideosCount();
    },
    imageStudioCount() {
      return imageStudioService.getImagesCount();
    },
    toggleNavbar() {
      this.drawer = !this.drawer;
    },
    getHelpUrl() {
      return `${this.helpRoot}/${this.appVersion}/`;
    },
    isDermaidEnabled() {
      return userService.getDermaidEnabled();
    },
  },

};
</script>

<style scoped>
.ai-studio-text {
  color: #F26924;
  font-style: italic;
  margin-right: 4px;
}
</style>
