import Vue from 'vue';
import config from '../config';

export default {

  /**
     * Create the capture export
     *
     * @param {number} id - Capture identifier.
    */
  async createCaptureExport(captureId) {
    const result = await Vue.axios.post(`/api/capture_exports/${captureId}`);

    if (result && result.data.id) {
      return result.data.id;
    }

    return -1;
  },

  /**
     * Checks if the capture export is complete
     *
     * @param {number} id - Capture identifier.
    */
  async isCaptureExportComplete(captureExportId) {
    const result = await Vue.axios.get(`/api/capture_exports/${captureExportId}/progress`);

    // console.log(result);

    if (result && result.data.progress === 100) {
      return true;
    }

    return false;
  },

  /**
     * Gets a presigned s3 url for the capture export
     *
     * @param {number} id - Capture identifier.
     * @returns {string} - The url to download the capture export.
    */
  async getExportUrl(id) {
    try {
      const url = `/api/capture_exports/${id}`;
      const response = await Vue.axios.get(url);

      // We get a Url for cloud scenario
      if (response.data.url) {
        return response.data.url;
      }

      // Handle the local scenario here.
      return `${config.apiUrl}api/capture_exports/${id}`;
    } catch (e) {
      return '';
    }
  },
};
