var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-center image-container"},_vm._l((_vm.pageInfo.images),function(page,index){return _c('div',{key:index,staticClass:"py-0"},[_c('div',[(!page.isError)?_c('div',{style:({position: 'relative'})},[_c('Editor',{ref:`editor${index}`,refInFor:true,attrs:{"canvas-width":600+index,"canvas-height":600+index,"width":600+index,"height":600+index,"editor-number":index},on:{"image-loaded":function($event){return _vm.imageLoaded()}}}),_c('v-btn',{staticClass:"white--text fullscreen-btn",attrs:{"color":"transparent","fab":"","small":""},on:{"click":function($event){return _vm.toggleFullscreen(page.image)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" fullscreen ")])],1),(_vm.isFullscreen && _vm.currentImage === page.image)?_c('full-screen-project-viewer',{attrs:{"image-src":_vm.currentImage,"is-fullscreen":_vm.isFullscreen},on:{"update:isFullscreen":function($event){_vm.isFullscreen=$event},"update:is-fullscreen":[function($event){_vm.isFullscreen=$event},_vm.exitFullscreen]}}):_vm._e(),_vm._l((_vm.details),function(detail){return _c('div',{key:detail.id,style:({
            position: 'absolute',
            top: `${detail.y / 2048 * 600 - 18}px`,
            left: `${detail.x / 2048 * 600 - 18}px`,
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            zIndex: 1000
          }),on:{"mouseenter":function($event){return _vm.handleHoverDetail(detail)},"mouseleave":function($event){return _vm.clearSelectedDetail()}}})}),(_vm.highlightCoordinates.x != null && _vm.highlightCoordinates.y != null)?_c('svg',{style:({
            position: 'absolute',
            // eslint-disable-next-line max-len
            top: `${_vm.highlightCoordinates.y / 2048 * 600 - 18}px`,
            // eslint-disable-next-line max-len
            left: `${_vm.highlightCoordinates.x / 2048 * 600 - 18}px`}),attrs:{"width":36,"height":36}},[_c('rect',{attrs:{"fill":"transparent","stroke":"rgba(255, 0, 0, 0.85)","stroke-width":"4","width":36,"height":36}})]):_vm._e()],2):_c('div',{style:({position: 'relative'})},[_c('div',{staticStyle:{"width":"300px","padding":"10px","margin":"auto","text-align":"center","color":"red"}},[_vm._v(" Error analyzing page "+_vm._s(page.pageId)+", status "+_vm._s(page.status)+". ")])])]),_c('div',{staticClass:"image-container__image-info",style:({ width: _vm.canvasWidth + 'px' })},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"image-container__image-info--font"},[_vm._v(" Capture Id: "),_c('a',{staticClass:"capture-link",attrs:{"href":`/captures/${page.captureId}`}},[_vm._v(_vm._s(page.captureId))]),_vm._v(" Frame: "+_vm._s(page.frame + 1)+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"close-on-click":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-btn',_vm._g({attrs:{"color":"secondary","icon":"","tile":"","ripple":false}},on),[_c('v-icon',[_vm._v(" more_horiz ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.undo(index)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('imageStudio.undo'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.clear(index)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('imageStudio.clear'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeFromPage(index)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('imageStudio.removePage'))+" ")])],1)],1)],1)],1)],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }