<template>
  <div v-if="error">
    <h2>Error when logging in</h2>
    <div>
      <p>There was an error when logging in. Please try again.</p>
      <p>Error Code: {{ error }}</p>
      <button @click="restartLoginProcess">
        Retry
      </button>
    </div>
    <!-- Empty page while processing login response, since redirect will happen -->
  </div>
</template>

<script>
import userManager from '../js/auth/userManager';
import authService from '../js/services/authService';
import { ACCOUNTS_PAGE, LOGIN_PAGE } from '../js/router/pages';

export default {
  name: 'AuthCallback',
  data() {
    return {
      error: null,
    };
  },
  mounted() {
    this.handleCallback();
  },
  methods: {
    restartLoginProcess() {
      this.$router.push({ name: LOGIN_PAGE });
    },
    handleCallback() {
      // Handle the sign-in callback
      userManager.signinCallback()
        .then(async user => {
          // eslint-disable-next-line no-console
          console.log('Signin callback handled');

          await authService.loginWithToken(user.access_token);
          this.$router.push({ name: ACCOUNTS_PAGE });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error handling sign-in callback:', error);
          this.error = 'SIGN_IN_CALLBACK_ERROR';
        });
    },
  },
};
</script>
