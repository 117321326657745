<template>
  <v-container v-if="!cognitoEnabled"
               fluid
               class="login"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="login__container mx-auto my-12">
      <v-img
        class="flex"
        max-width="539"
        src="@/assets/logo_LG.png"
      />
      <v-card-title class="login__container--title">
        {{ $t('pageTitles.login') }}
      </v-card-title>

      <v-form
        ref="form"
        v-model="canLogin"
        @submit.prevent="onLogin"
      >
        <v-label>{{ $t('loginPage.username') }}</v-label>
        <v-text-field
          v-model="username"
          :rules="nameRules"
          color="grey darken-1"
          required
        />
        <v-label>{{ $t('loginPage.password') }}</v-label>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          type="password"
          color="grey darken-1"
          required
        />
        <v-card-actions class="login__container--actions">
          <v-btn
            :disabled="!canLogin"
            type="submit"
            class="secondary white--text mr-2"
            @click="onLogin"
          >
            {{ $t('loginPage.login') }}
          </v-btn>
          <v-btn
            class="grey lighten-3 ml-2"
            @click="enableForgotPassword"
          >
            {{ $t('loginPage.forgotPassword') }}
          </v-btn>
        </v-card-actions>
      </v-form>

      <app-version class="login__container--app-version" />
    </v-card>
  </v-container>
</template>

<script>
import AppVersion from '../components/AppVersion.vue';
import authService from '../js/services/authService';
import { ACCOUNTS_PAGE, FORGOT_PASSWORD_PAGE, LOGIN_PAGE } from '../js/router/pages';
import applicationService from '../js/services/applicationService';
import dermaidService from '../js/services/dermaidService';
import userManager from '../js/auth/userManager';

export default {
  name:       'Login',
  components: { AppVersion },
  data() {
    return {
      cognitoEnabled: applicationService.isCognitoEnabled(),
      message:        '',
      showMessage:    false,
      colorMessage:   'success',
      canLogin:       false,
      appVersion:     '',
      username:       '',
      password:       '',

      isCloudEnv:    applicationService.isInCloudMode(),
      passwordRules: [
        v => !!v || this.$t('loginPage.passwordValidate'),
      ],
      nameRules: [
        v => !!v || this.$t('loginPage.usernameValidate'),
      ],
    };
  },
  mounted() {
    authService.logout();

    if (this.$data.cognitoEnabled) {
      // eslint-disable-next-line no-console
      console.log('using cognito login flow');

      userManager.signinRedirect();

      return;
    }

    // eslint-disable-next-line no-console
    console.log('Mounted');

    const username = window.sessionStorage.getItem('browserUsername');
    const password = window.sessionStorage.getItem('browserPassword');

    if (username && password) {
      // eslint-disable-next-line no-console
      console.log('Got Session storage');
      this.username = username;
      this.password = password;

      this.submit().then(response => {
        // eslint-disable-next-line no-console
        console.log('Submitted', response);
      });
    }
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },

    /**
     * Submit login request.
     */
    async onLogin() {
      if (!this.canLogin) {
        return;
      }

      try {
        // eslint-disable-next-line no-console
        console.log('Logging in ');

        const route = await authService.login(this.username, this.password);

        // eslint-disable-next-line no-console
        console.log('Logged In');

        const dermaidEnabled = await dermaidService.fetchDermaidEnabled();

        dermaidService.setDermaidEnabled(dermaidEnabled);

        if (route && route.name && route.name !== LOGIN_PAGE) {
          this.$router.push(route.path);
          authService.clearLoginRedirectRoute();
        } else {
          this.$router.push({ name: ACCOUNTS_PAGE });
        }
      } catch (error) {
        const errorMsg = (error && error.data) ? error.data.title : this.$t('loginPage.unknownError');

        this.showErrorMessage(errorMsg);
      }
    },

    /**
     * Show the forgot password dialog
     */
    enableForgotPassword() {
      this.$router.push({ name: FORGOT_PASSWORD_PAGE });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.login {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 360px) {
  .login{
    &__container {
      &--actions {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

</style>
