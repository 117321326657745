<template>
  <v-dialog v-if="accountValues"
            v-model="showDialog"
            max-width="800"
  >
    <v-card>
      <v-card-title class="text-h6 white--text primary mb-4">
        {{ isNew ? $t('addAccount.title') : $t('editAccount.title') }}
        <v-spacer />
        <v-btn icon
               dark
               @click="close()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="form"
              v-model="valid"
      >
        <v-container>
          <v-row>
            <v-col v-for="field in dataFields"
                   :key="field.id"
                   :cols="field.width"
                   class="pa-3"
            >
              <v-menu v-if="field.type === fieldTypes.date"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field v-model="accountValues[field.key]"
                                :label="getLabel(field)"
                                :rules="field.required ?
                                  [rules.required, rules.date] : [rules.date]"
                                append-icon="event"
                                v-on="on"
                                @blur="date = formatDate(accountValues[field.key])"
                  />
                </template>
                <v-date-picker v-model="date"
                               no-title
                               :max="currentDate"
                               @input="datePicker = false"
                />
              </v-menu>
              <v-select v-else-if="field.type === fieldTypes.gender"
                        v-model="accountValues[field.key]"
                        :label="getLabel(field)"
                        :items="genders"
                        :rules="field.required ? [rules.required] : []"
              />
              <v-text-field v-else-if="field.type === fieldTypes.height"
                            v-model="accountValues[field.key]"
                            type="number"
                            :min="0"
                            :label="getLabel(field)"
                            :rules="field.required ? [rules.required] : []"
              />
              <v-text-field v-else-if="field.type === fieldTypes.email"
                            v-model="accountValues[field.key]"
                            type="email"
                            :label="getLabel(field)"
                            :rules="field.required ? [rules.required, rules.email] : [rules.email]"
              />
              <v-text-field v-else
                            v-model="accountValues[field.key]"
                            :label="getLabel(field)"
                            :rules="field.required ? [rules.required] : []"
                            color="grey darken-1"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="text-md-center">
          <v-btn :disabled="!valid"
                 class="secondary white--text"
                 @click="saveAccount()"
          >
            {{ isNew ? $t('addAccount.ok') : $t('editAccount.ok') }}
          </v-btn>
          <v-btn class="gray darken-3"
                 @click="close()"
          >
            {{ isNew ? $t('addAccount.cancel') : $t('editAccount.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import accountFieldsService from '../../js/services/accountFieldsService';
import accountsService from '../../js/services/accountsService';
import constants from '../../js/constants';

export default {
  name:  'AccountData',
  props: {
    dialog: {
      type:    Boolean,
      default: false,
    },
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data()  {
    return {
      valid:            false,
      success:          '',
      error:            '',
      date:             null,
      showDialog:       false,
      changedDocuments: [],
      rules:            {
        required: value => !!value || this.$t('addAccount.requiredField'),
        email:    value => (!value || constants.EMAIL_PATTERN.test(value)) || this.$t('addAccount.invalidEmail'),
        date:     value => (!value || (constants.DATE_PATTERN.test(value) && this.lessThanCurrentDate(value))) || this.$t('addAccount.invalidDate'),
      },
      accountValues: {},
      datePicker:    false,
      genders:       [
        {
          text:  this.$t('accountPage.gender[0]'),  // "Select Gender"
          value: '',  // Empty value for "Select Gender"
        },
        {
          text:  this.$t('accountPage.gender[1]'),  // "Male"
          value: '1',
        },
        {
          text:  this.$t('accountPage.gender[2]'),  // "Female"
          value: '2',
        },
        {
          text:  this.$t('accountPage.gender[3]'),  // "Other"
          value: '3',
        },
      ],

      fieldTypes:  accountsService.accountFieldTypes,
      currentDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    /**
     * List of account's data fields.
     *
     * @return {Array}
     */
    dataFields() {
      return accountFieldsService.getAccountFields();
    },

    /**
     * Account's data fields formatted for saving request.
     *
     * @return {Array}
     */
    accountFields() {
      const accountFields = [];

      this.dataFields.forEach(field => {
        if (this.accountValues[field.key]) {
          accountFields.push({
            key_id: field.id,
            value:  this.accountValues[field.key],
          });
        }
      });

      return accountFields;
    },

    /**
     * Returns true if dialog open to add new account.
     *
     * @return {boolean}
     */
    isNew() {
      return !this.account || !this.account.id;
    },
  },
  watch: {
    dialog(val) {
      this.showDialog = val;
    },
    account() {
      this.setAccountValues();
    },
    date(val) {
      this.accountValues.dob = this.formatDate(val);
    },
  },
  mounted() {
    this.setAccountValues();
  },
  methods: {
    /**
     * Format Day of birth.
     *
     * @return {null|string}
     */
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [ year, month, day ] = date.split('-');

      if (!year || !month || !day) {
        return null;
      }

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    /**
     * Compares specified date with the current one.
     */
    lessThanCurrentDate(date) {
      return Date.parse(this.currentDate) >= Date.parse(date);
    },
    setAccountValues() {
      if (this.account && this.account.id) {
        this.accountValues = { ...this.accountValues, ...this.account.fields };
      }
    },
    /**
     * Return label for field input.
     *
     * @return {string}
     */
    getLabel(field) {
      return field.description + (field.required  ? '*' : '');
    },

    /**
     * Add or update account's data.
     */
    async saveAccount() {
      this.success = '';
      this.error = '';

      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        try {
          const res = await accountsService.createAccount(this.accountFields);

          this.successClose(res);
        } catch (error) {
          this.setSaveError(error);
        }
      } else {
        try {
          await accountsService.updateAccount(this.account.id, this.accountFields);

          this.successClose();
        } catch (error) {
          this.setSaveError(error);
        }
      }
    },

    /**
     * Launch when account successfully saved.
     */
    successClose(res) {
      this.showDialog = false;
      if (res && res.account_id) {
        this.$emit('saved', res.account_id);
      } else {
        this.$emit('saved');
      }
    },

    /**
     * Launch when account not saved.
     */
    setSaveError() {
      /* if (error.response && error.response.data && error.response.data.Message) {
        this.error = error.response.data.Message;
      } else {
        this.error = this.isNew ? this.$t('addAccount.error') : this.$t('editAccount.error');
      }*/
      this.showDialog = false;
      this.$emit('error');
    },

    /**
     * Close dialog.
     */
    close() {
      this.showDialog = false;
      this.success = '';
      this.error = '';
      if (this.isNew)  {
        this.$refs.form.reset();
      }

      this.$emit('close');
    },

    fileChanged(id) {
      this.changedDocuments.push(id);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
</style>
