<template>
  <v-container class="tabBackground">
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="fields"
          item-key="key"
          single-line
          hide-details
        >
          <template #[`item.key`]="{ item }">
            <td>{{ item.key }}</td>
          </template>

          <template #[`item.description`]="{ item }">
            <td class="small-text">
              {{ item.description }}
            </td>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu bottom
                    right
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-2 hover-actions"
                  v-bind="attrs"
                  v-on="on"
                >
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openEditDialog(item)">
                  <v-list-item-title>{{ $t('organizationSettingsTab.edit') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
    </v-row>

    <v-dialog
      v-model="mfaSettingsDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          <span class="text-h5">
            {{
              $t('organizationSettingsTab.mfaSettings')
            }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-row class="py-2 align-center">
            <v-col cols="6"
                   class="text-left"
            >
              <strong>{{ $t('organizationSettingsTab.name') }}</strong>
            </v-col>
            <v-col cols="6"
                   class="align-button-end"
            >
              <v-switch
                v-model="mfaSettings.enabled"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row class="py-2">
            <v-col cols="6"
                   class="text-left"
            >
              <strong>{{ $t('organizationSettingsTab.fields.method') }}</strong>
            </v-col>
            <v-col cols="6"
                   class="text-right"
            >
              <strong>{{ $t('organizationSettingsTab.fields.active') }}</strong>
            </v-col>
          </v-row>
          <v-divider />

          <v-row class="py-2 align-items-center">
            <v-col cols="6"
                   class="text-left"
            >
              <div>{{ $t('organizationSettingsTab.authenticator') }}</div>
            </v-col>
            <v-col cols="6"
                   class="text-right"
            >
              <v-row justify="end">
                <v-checkbox
                  v-model="mfaSettings.totpEnabled"
                  :disabled="!mfaSettings.enabled"
                  hide-details
                  color="secondary"
                  class="checkbox-margin"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn color="gray darken-3"
                 class="mx-2"
                 :disabled="isSaving"
                 @click="mfaSettingsDialog = false"
          >
            {{ $t('organizationSettingsTab.fields.cancel') }}
          </v-btn>
          <v-btn color="secondary"
                 class="mx-2"
                 :disabled="isSaving"
                 @click="saveMfaSettings"
          >
            {{ $t('organizationSettingsTab.fields.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="successSnackbar.show"
                color="secondary"
                class="snackbar-centered text-center"
    >
      <div class="font-weight-bold text-center">
        {{ successSnackbar.message }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="errorSnackBar.show"
                color="error"
                timeout="2000"
                class="snackbar-centered text-center"
    >
      <div class="font-weight-bold text-center">
        {{ errorSnackBar.message }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>

import organizationService from '../../js/services/organizationService';

export default {
  name: 'OrganizationSettings',
  data() {
    return {
      mfaSettingsDialog: false,
      successSnackbar:   { show: false, message: '' },
      errorSnackBar:     { show: false, message: '' },
      isSaving:          false,
      mfaSettings:       {
        enabled:     false,
        totpEnabled: false,
      },
      headers: [
        {
          text:     this.$t('organizationSettingsTab.fields.key'),
          value:    'key',
          align:    'start',
          sortable: false,
        },
        {
          text:     this.$t('organizationSettingsTab.fields.description'),
          value:    'description',
          align:    'start',
          sortable: false,
        },
        {
          text:     this.$t('organizationSettingsTab.fields.actions'),
          value:    'actions',
          align:    'center',
          sortable: false,
        },
      ],
      fields: [
        {
          key:         this.$t('organizationSettingsTab.name'),
          description: this.$t('organizationSettingsTab.details'),
        },
      ],
    };
  },

  watch: {
    'mfaSettings.enabled': 'handleMfaToggle',
  },

  methods: {
    openEditDialog() {
      this.loadMfaSettings();
      this.mfaSettingsDialog = true;
    },
    async loadMfaSettings() {
      try {
        const response = await organizationService.getMfaSettings();

        this.mfaSettings.enabled = response.enabled;
        this.mfaSettings.totpEnabled = response.totpEnabled;
      } catch (error) {
        this.errorSnackBar.message = 'Failed to load MFA settings';
        this.errorSnackBar.show = true;
      }
    },

    handleMfaToggle(newValue) {
      if (!newValue) {
        this.mfaSettings.totpEnabled = false;
      }
    },

    validateMfaSettings() {
      if (this.mfaSettings.enabled
      && !this.mfaSettings.totpEnabled

      ) {
        this.errorSnackBar.message = 'Please enable the Authenticator (TOTP) when MFA is enabled.';
        this.errorSnackBar.show = true;

        return false;
      }

      return true;
    },

    async saveMfaSettings() {
      if (!this.validateMfaSettings()) {
        return;
      }

      this.isSaving = true;
      try {
        const payload = {
          enabled:     this.mfaSettings.enabled,
          totpEnabled: this.mfaSettings.enabled ? this.mfaSettings.totpEnabled : false,
        };

        await organizationService.updateMfaSettings(payload);
        this.successSnackbar.message = 'Multi-Factor Auth settings updated';
        this.successSnackbar.show = true;
        this.mfaSettingsDialog = false;
      } catch (error) {
        this.errorSnackBar.message = 'Failed to update MFA settings';
        this.errorSnackBar.show = true;
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }

</style>
