<template>
  <div class="image-container">
    <div v-if="!pageInfo.isError">
      <div v-for="(page, index) in pageInfo.images"

           :key="index"
           class="py-0"
      >
        <div v-if="!page.isError">
          <div :style="{ position: 'relative' }">
            <Editor :ref="`editor${index}`"
                    :canvas-width="canvasWidth"
                    :canvas-height="canvasHeight"
                    :width="canvasWidth"
                    :height="canvasHeight"
                    :enable-zoom="enableZoom"
                    :editor-number="index"
                    :details="details"
                    @canvas-size-changed="canvasSizeChanged"
                    @zoom-changed="onZoomChanged"
                    @cursor-over-lesion="onCursorOverLesion"
            />
          </div>
          <div v-if="showImageInfo"
               class="image-container__image-info"
               :style="{ width: canvasWidth + 'px' }"
          >
            <div class="d-flex align-center">
              <span class="image-container__image-info--font">
                Capture Id:
                <a :href="`/captures/${page.captureId}`"
                   class="capture-link"
                >{{ page.captureId }}</a>
                Frame: {{ page.frame + 1 }}
              </span>
              <v-spacer />
              <v-col cols="3" />
              <v-col cols="1">
                <v-menu close-on-click
                        offset-y
                        offset-x
                >
                  <template #activator="{ on }">
                    <v-btn color="secondary"
                           icon
                           tile
                           :ripple="false"
                           v-on="on"
                    >
                      <v-icon>
                        more_horiz
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="undo(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.undo') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clear(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.clear') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeFromPage(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.removePage') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageInfo.isError">
      <div v-for="(page, index) in pageInfo.images"

           :key="index"
           class="py-0"
      >
        <div v-if="page.isError">
          <div :style="{ position: 'relative' }">
            <div class="page-error">
              <Editor :ref="`editor${index}`"
                      :canvas-width="canvasWidth"
                      :canvas-height="canvasHeight"
                      :width="canvasWidth"
                      :height="canvasHeight"
                      :enable-zoom="enableZoom"
                      :editor-number="index"
                      :details="details"
                      @canvas-size-changed="canvasSizeChanged"
                      @zoom-changed="onZoomChanged"
                      @cursor-over-lesion="onCursorOverLesion"
              />

              <div class="success-message">
                <div class="icon-container">
                  <div class="checkmark">
                    &#10003;
                  </div>
                </div>
                <div class="text-container">
                  <span><strong>Screening Complete:<br>  </strong>No Lesions Detected</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showImageInfo"
               class="image-container__image-info"
               :style="{ width: canvasWidth + 'px' }"
          >
            <div class="d-flex align-center">
              <span class="image-container__image-info--font">
                Capture Id:
                <a v-if="pageInfo.captureId"
                   :href="`/captures/${pageInfo.captureId}`"
                   class="capture-link"
                >{{ pageInfo.captureId }}</a>
                <span v-else>N/A</span>
                Frame: {{ pageInfo.angle != null ? pageInfo.angle + 1 : 'N/A' }}
              </span>
              <v-spacer />
              <v-col cols="3" />
              <v-col cols="1">
                <v-menu close-on-click
                        offset-y
                        offset-x
                >
                  <template #activator="{ on }">
                    <v-btn color="secondary"
                           icon
                           tile
                           :ripple="false"
                           v-on="on"
                    >
                      <v-icon>
                        more_horiz
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="undo(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.undo') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clear(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.clear') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeFromPage(index)">
                      <v-list-item-title>
                        {{ $t('imageStudio.removePage') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from './Editor.vue';
import config from '../../js/config';
import imagesService from '../../js/services/imagesService';
import imageStudioService from '../../js/services/imageStudioService';

export default {
  name:       'FullscreenImageEditorPage',
  components: {
    Editor,
  },
  props: {
    isDermaidViewer: {
      type:    Boolean,
      default: false,
    },
    enableZoom: {
      type:    Boolean,
      default: false,
    },
    showImageInfo: {
      type:    Boolean,
      default: true,
    },
    pageInfo: {
      type: Object,
      default() {
        return {
          images:    [],
          commands:  [],
          captureId: null,
          angle:     null,
        };
      },
    },
    canvasWidth: {
      type:    Number,
      default: 600,
    },
    canvasHeight: {
      type:    Number,
      default: 600,
    },
    pageIndex: {
      type:    Number,
      default: 0,
    },
    highlightCoordinates: {
      type:    Object,
      default: null,
    },
    details: {
      type:    Array,
      default: () => [],
    },
    handleHoverDetail: {
      type:    Function,
      default: () => { },
    },
    clearSelectedDetail: {
      type:    Function,
      default: () => { },
    },
  },
  data() {
    return {
      imageLoadedCount: 0,
      currentImage:     null,
    };
  },
  watch: {
    pageInfo: {
      deep: true,
      handler() {
        this.loadContent();
      },
    },
  },
  beforeMount() {
    this.loadContent();
  },
  beforeDestroy() {
    this.saveCommands();
    this.$context.history.clear();
  },
  methods: {
    onCursorOverLesion(event) {
      this.$emit('cursor-over-lesion', event);
    },
    onZoomChanged(zoom) {
      this.$emit('zoom-changed', zoom);
    },
    canvasSizeChanged() {
      this.loadContent();
    },
    loadContent() {
      this.imageLoadedCount = 0;
      this.pageInfo.images.forEach((page, index) => {
        const imageToLoad = page.imageUrl || page.image;

        if (!imageToLoad) {
          return;
        }

        if (imageToLoad.startsWith('api/')) {
          page.imageUrl = `${config.apiUrl}${imageToLoad}`;
        }

        this.loadFrame(index, imageToLoad);
      });
    },

    async loadFrame(index, url) {
      if (url && url !== '') {
        try {
          const img = await imagesService.loadImage(url);
          const blob = await fetch(img).then(res => res.blob());

          const e = { target: { files: [blob] } };
          const editor = this.$refs[`editor${index}`][0];

          if (editor) {
            editor.setImage(e);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error loading image', error);
        }
      }
    },
    saveCommands() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        if (this.pageInfo.images[i].isError) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const editor = this.$refs[`editor${i}`][0];

        editor.endTextDraw();
      }

      const cmds = this.getCommands();

      this.$emit('commands-updated', this.pageIndex, cmds);
    },
    drawRect(x, y, width, height) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.drawRect({
          x, y, width, height,
        });
      }
    },
    enableLine(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableFreeDraw(params);
      }
    },
    changeZoom(value) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.changeZoom(value);
      }
    },
    enableRectangle(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableRect(params);
      }
    },
    enableEllipse(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableEllipse(params);
      }
    },
    enableText(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableText(params);
      }
    },
    enableArrow(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableArrow(params);
      }
    },
    enableCrop() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableCrop();
      }
    },
    undo(index) {
      if (index) {
        let found = 0;
        const cmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (index === cmd.cid && !found) {
            found = 1;
            cmd.undo();
          } else {
            cmds.push(cmd);
          }
        }
        cmds.reverse();
        cmds.forEach(c => {
          this.$context.history.push(c);
        });
      } else if (this.$context.history.length) {
        this.$context.history.pop().undo();
      }

      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.refresh();
      }
    },
    clear(index) {
      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (cmd.cid === index) {
          cmd.undo();
        } else {
          cmds.push(cmd);
        }
      }
      cmds.reverse();
      cmds.forEach(c => {
        this.$context.history.push(c);
      });

      const editor = this.$refs[`editor${index}`][0];

      editor.refresh();
    },
    removeCommands(imageIndex) {
      // eslint-disable-next-line no-console
      console.log(`Removing commands from page ${imageIndex}`, this.getCommands());

      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (imageIndex !== cmd.cid) {
          cmds.push(cmd);
        }

        if (cmd.cid > imageIndex) {
          // console.log(`Removing command decrementing ${cmd.cid}`);
          cmd.cid--;
        }
      }

      cmds.reverse();
      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }
    },
    getCommands() {
      const exportInfo = [];
      const cmds = [];

      // console.log(`getCommands ${this.$context.history.length}`);
      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        exportInfo.push(cmd.export());
        cmds.push(cmd);
      }
      cmds.reverse();

      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }

      return exportInfo.reverse();
    },
    async save(projectId, pageNumber) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        const bgParams = {
          scaleX: editor.canvas.width / (editor.canvas.backgroundImage.scaleX
            * editor.canvas.backgroundImage.width),
          scaleY: editor.canvas.height / (editor.canvas.backgroundImage.scaleY
            * editor.canvas.backgroundImage.height),
          translateX: editor.canvas.backgroundImage.left
            / editor.canvas.backgroundImage.scaleX,
          translateY: editor.canvas.backgroundImage.top
            / editor.canvas.backgroundImage.scaleY,
        };

        const imgData = editor.saveImage();
        const cmds = [];
        const currentCmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (cmd.cid === i) {
            cmds.push(cmd.export());
          }

          currentCmds.push(cmd);
        }
        cmds.reverse();
        currentCmds.reverse();

        while (currentCmds.length > 0) {
          this.$context.history.push(currentCmds.pop());
        }

        const data = {
          captureId:               this.pageInfo.images[i].captureId,
          cameraCaptureSettingsId: this.pageInfo.images[i].cameraCaptureSettingsId,
          angle:                   this.pageInfo.images[i].angle,
          frame:                   this.pageInfo.images[i].frame,
          commands:                JSON.stringify(cmds),
          cropInfo:                bgParams,
          pageNumber,
        };

        data.data = imgData;

        // TODO We disable this warning because it must be done in a loop because the
        // API only handles it one at a time. We can do better and allow the API to do one
        // upload with all the images. Future work.
        /* eslint-disable no-await-in-loop */
        await imageStudioService.uploadProjectImage(projectId, data);
      }
    },
    removeFromPage(index) {
      this.$emit('remove-page', index);
    },
  },

};
</script>

<style lang="scss" scoped>
.image-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  &__image-info {
    padding-top: 20px;
    display: contents;
  }

  .analysis-details {
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 1000;
  }
}

.image-container>div:only-child {
  grid-column: 1 / -1;
}

@media (max-width: 768px) {
  .image-container {
    grid-template-columns: 1fr;
  }
}

.page-error {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-message {
    position: absolute;
    bottom: 20px;
    left: 10px;
    background-color: #002a4a;
    color: black;
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  strong {
    display: inline;
    font-weight: 500;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #99b83c;
    border-radius: 50%;
  }
  .checkmark {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  .fullscreen-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  .text-container {
      background-color: white;
      border-radius: 8px;
      padding: 5px 10px;
  }
  .fullscreen-image {
    width: 600px;
    height: 600px;
    object-fit: contain;
  }
}

.capture-link {
  color: #007BFF;
  text-decoration: underline;

  &:hover {
    color: darken(#007BFF, 10%);
  }
}

</style>
