import { UserManager } from 'oidc-client-ts';
import applicationService from '../services/applicationService';

const clientId = applicationService.getCognitoClientId();
const cognitoPoolId = applicationService.getCognitoUserPoolId();
const region = applicationService.getCognitoRegion();

const userManager = new UserManager({
  authority:     `https://cognito-idp.${region}.amazonaws.com/${cognitoPoolId}`,
  client_id:     clientId,
  redirect_uri:  `${window.location.origin}/oauth/callback`,
  response_type: 'code',
  scope:         'openid profile email',
});

export default userManager;
